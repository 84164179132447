import {useState, useEffect} from "react";
import { store } from "./store";
import produce from "immer";
import app from '../setupAxios';
import {getState, resetStoreKeys, subscribe} from "./helpers";
import {useAccessToken} from "./auth/auth";
import {DependencyMapper} from "../util";

/**********************************************************************************************************************
 DEFINITIONS
 **********************************************************************************************************************/
const storePath = ["user"];
const initialState = {
    // client: null,
    // user: null
};


/**********************************************************************************************************************
 REDUCERS
 **********************************************************************************************************************/
const reducers = {
    storeUser: (state, payload) =>
        produce(state, draft => {
            const { user } = payload;
            Object.keys(draft).forEach(key => { delete draft[key]; });
            Object.assign(draft,user);
        }),
    storeP2pDiscoverable: (state, payload) =>
        produce(state, draft => {
            const { value } = payload;
            if (!draft.p2pSearch) {
                draft.p2pSearch = {};
            }
            draft.p2pSearch.discoverable = value;
        }),
    'reset.user': (state, payload) =>
        produce(state, draft => {
            resetStoreKeys(state,draft,initialState,payload);
        }),
};


/**********************************************************************************************************************
 INJECT REDUCERS INTO REDUX STORE
 **********************************************************************************************************************/
store.injectReducer(storePath, (state = initialState, { type, payload }) =>
    reducers[type] ? reducers[type](state, payload) : state
);


/**********************************************************************************************************************
 EXPORTS
 **********************************************************************************************************************/

export const useUser = (fetch = false) => {
    const [state, setState] = useState(getState(storePath));
    useEffect(() => subscribe(setState,storePath), [setState]);

    useEffect(() => {

        DependencyMapper.on('dataFetchReady','getUser',() => {
            if(Object.keys(state).length === 0) {
                getUser();
            }
        });

        if (fetch === true) {
            getUser();
        }
    }, [])
    return state;
};

export const getUser = async () => {
    try {
        const response = await app.get('api/v1/users/summary');
        const { user } = response.data;
        if (response.status === 200) {
            // storeClient({ client });
            storeUser({ user });
            DependencyMapper.reg("userState");
        }
    }catch(e){
        console.error("Error in getUser(): ",e);
    }
};

export const storeP2pDiscoverable = (value) => {store.dispatch({ type: "storeP2pDiscoverable", payload: { value } });};
export const storeUser = response => store.dispatch({ type: "storeUser", payload: response });
