class DateToolsController {
    currentDate = new Date();
    setCurrentDate = function(date){
        if(typeof(date) !== 'undefined') {
            this.currentDate = new Date(date);
        }
    };

    /**
     * @author KCupp, JBonnell
     * @returns {Date}
     */
    getToday = () => {
        return this.currentDate;
    };

    /**
     * @author KCupp, JBonnell
     * @returns {number}
     */
    getCurrentTime = () => {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        return new Date(this.getToday().getTime() + now.getTime() - today.getTime());
    };


    // Function to determine if a given date is in Daylight Saving Time (DST) for Eastern Time
    // hours parameter represents the current hour to check against (defaults to 2)
    isDST = (date, hours = 2) => {
        const year = date.getFullYear();

        // Get the second Sunday of March
        const secondSundayMarch = getSecondSundayOfMarch(year);

        // Get the first Sunday of November
        const firstSundayNovember = getFirstSundayOfNovember(year);

        // Check if it's the exact second Sunday of March
        const isSecondSundayOfMarch =
            date.getDate() === secondSundayMarch.getDate() &&
            date.getMonth() === secondSundayMarch.getMonth() &&
            date.getFullYear() === secondSundayMarch.getFullYear();

        // Check if it's the exact first Sunday of November
        const isFirstSundayOfNovember =
            date.getDate() === firstSundayNovember.getDate() &&
            date.getMonth() === firstSundayNovember.getMonth() &&
            date.getFullYear() === firstSundayNovember.getFullYear();

        // If it's the second Sunday of March, DST starts at 2 AM
        if (isSecondSundayOfMarch) {
            return hours >= 2; // Only in DST if 2 AM or later
        }

        // If it's the first Sunday of November, DST ends at 2 AM
        if (isFirstSundayOfNovember) {
            return hours < 2; // Still in DST before 2 AM
        }

        // Return true if the date falls within the DST range
        return date >= secondSundayMarch && date < firstSundayNovember;
    };

}

// Function to calculate the second Sunday of March for a given year
const getSecondSundayOfMarch = (year) => {
    let march1 = new Date(year, 2, 1); // March 1st (Month is 0-indexed, so 2 = March)
    let firstSunday = 1 + (7 - march1.getDay()) % 7; // Find the first Sunday
    let secondSunday = firstSunday + 7; // The second Sunday is 7 days after the first Sunday
    return new Date(year, 2, secondSunday); // Return the exact second Sunday of March
};

// Function to calculate the first Sunday of November for a given year
const getFirstSundayOfNovember = (year) => {
    let november1 = new Date(year, 10, 1); // November 1st (Month is 0-indexed, so 10 = November)
    let firstSunday = 1 + (7 - november1.getDay()) % 7; // Find the first Sunday
    return new Date(year, 10, firstSunday); // Return the exact first Sunday of November
};

export const dateTools = new DateToolsController();
