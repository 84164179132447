import get from "lodash/get";
import {useMedia} from "react-media";
import React, {useEffect, useState} from 'react';

export const interpolate = (input, dflt = null, symbolTable = null) => {
    try {
        if (typeof input === "undefined") return dflt;
        if (symbolTable === null) {
            symbolTable = {};
        }
        let aryInput = Array.isArray(input) ? input : [input];
        let result = aryInput.map(val => {
            let type = typeof val;
            switch (type) {
                case "string": {
                    if (/^\{.*\}$/.test(val)) return get(symbolTable, val.substring(1, val.length - 1), dflt);
                    else return val;
                }
                default:
                    return val;
            }
        });
        if (Array.isArray(input)) return result;
        else return result.shift();
    } catch (e) {
        console.log("Error in shared:interpolate()::", e);
        return dflt;
    }
}

export const formatPhoneNo = (phoneNo) =>{
    try{
        //Filter only numbers from the input
        let cleaned = ('' + phoneNo).replace(/\D/g, '');
        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null;
    } catch (e){
        console.log("Error in shared:formatPhoneNo()::", e);
        return {};
    }
};

export const DateToCountdown = (stopDate, startDate=null, ignoreTimezone=false) =>{
    try{
        let endDate = ignoreTimezone ? moment.utc(stopDate).valueOf() : new Date(stopDate);
        let stop = ignoreTimezone ? endDate : Date.UTC(endDate.getUTCFullYear(),endDate.getUTCMonth(),
            endDate.getUTCDate(), endDate.getUTCHours(),
            endDate.getUTCMinutes(), endDate.getUTCSeconds());

        let today = startDate ?? new Date();
        let start = startDate ? startDate : Date.UTC(today.getUTCFullYear(),today.getUTCMonth(),
            today.getUTCDate(), today.getUTCHours(),
            today.getUTCMinutes(), today.getUTCSeconds());

        if(!stop || moment(start).isAfter(stop)){
            return {
                "days":0,
                "hours":0,
                "minutes":0
            }
        }

        const dateDiff = stop  - start;
        const days = Math.floor( dateDiff/(86400000));
        const hours = Math.floor( (dateDiff/(3600000)) % 24 );
        const minutes = Math.floor( (dateDiff/60000) % 60 );

        return {
            "days":days,
            "hours":hours,
            "minutes":minutes
        }
    } catch (e){
        console.log("Error in shared:DateToCountdown()::", e);
        return {};
    }

}

export const svgStar = (centerX, centerY, points, innerRadius, outerRadius) => {
    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    }
    try {
        const degreeIncrement = 360 / (points * 2);
        const d = new Array(points * 2).fill('foo').map((p, i) => {
            let radius = i % 2 == 0 ? outerRadius : innerRadius;
            let degrees = degreeIncrement * i;
            const point = polarToCartesian(centerX, centerY, radius, degrees);
            return `${point.x},${point.y}`;
        });
        return `M${d}Z`;
    } catch (e) {
        console.log("Error in shared:svgStar()::", e);
        return {};
    }
}
/*
    Use media queries in code logic.

    example:
    const mediaMatches = useMediaMatches();
    if(mediaMatches.media.large){...} // execute if screen at least large
    if(mediaMatches.exact.large){...} // execute if screen is large exactly (>= large && < xlarge)
 */
export const useMediaMatches = () => {

    // these breakpoints are taken from react-bootstrap's breakpoints list https://react-bootstrap.github.io/layout/breakpoints/
    const mediaMatches = useMedia({
        queries:{
            xsmall: "(min-width: 0)",
            small:  "(min-width: 576px)",
            medium: "(min-width: 768px)",
            large:  "(min-width: 992px)",
            xlarge: "(min-width: 1200px)",
            xxlarge:"(min-width: 1400px)"
        }});

    return {
        media:mediaMatches,
        exact:{
            xsmall: mediaMatches.xsmall && !mediaMatches.small,
            small:  mediaMatches.small  && !mediaMatches.medium,
            medium: mediaMatches.medium && !mediaMatches.large,
            large:  mediaMatches.large  && !mediaMatches.xlarge,
            xlarge: mediaMatches.xlarge && !mediaMatches.xxlarge,
            xxlarge:mediaMatches.xxlarge
        }
    };
}

//performs a deep merge of multiple objects.
//The function takes in any number of objects as arguments and returns a new object that contains all the properties of the input objects.
//If two or more input objects have the same property, the function will recursively merge the values of those properties.
export const mergeDeep = (...objects) => {
    const isObject = obj => obj && typeof obj === 'object';
    return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach(key => {
            const pVal = prev[key];
            const oVal = obj[key];
            if (Array.isArray(pVal) && Array.isArray(oVal)) {
                prev[key] = pVal;
            }
            else if (isObject(pVal) && isObject(oVal)) {
                prev[key] = mergeDeep(pVal, oVal);
            }
            else {
                prev[key] = oVal;
            }
        });
        return prev;
    }, {});
};


export const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useEffect(() => {
        const getDimensions = () => ({
            width: myRef.current.offsetWidth,
            height: myRef.current.offsetHeight
        })

        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setDimensions(getDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [myRef])

    return dimensions;
};

export const toCamelCase = (inputString) => {
    // Replace apostrophes with an empty string, remove non-alphanumeric characters, and split the string into words
    const words = inputString.replace(/'/g, '').replace(/[^a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF]/g, ' ').split(/\s+/); // include latin chars with diacritics

    // Capitalize the first letter of each word and make the rest lowercase
    const result = words.map((word, index) => {
        if (word) {
            return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return '';
    });

    // Join the result array into a single string and return it
    return result.join('');
};

export const getOrdinalSuffix = (number, lang = 'en_US') => {
    // Input validation
    if (number === null || number === undefined || number === '' || isNaN(number)) {
        console.error('getOrdinalSuffix Invalid input: number must be a valid number');
        return;
    }

    switch (lang) {
        case 'es_US': {
            // Spanish ordinal suffixes
            const suffixes = {
                1: "º", // primero
                2: "º", // segundo
                3: "º", // tercero
                4: "º", // cuarto
                5: "º", // quinto
                6: "º", // sexto
                7: "º", // séptimo
                8: "º", // octavo
                9: "º", // noveno
                10: "º", // décimo
            };

            return number <= 10
                ? `${number}${suffixes[number]}`
                : `${number}º`; // Default suffix for Spanish after 10
        }

        case 'en_US':
        default: {
            const remainderTen = number % 10;
            const remainderHundred = number % 100;

            if (remainderTen === 1 && remainderHundred !== 11) {
                return `${number}st`;
            } else if (remainderTen === 2 && remainderHundred !== 12) {
                return `${number}nd`;
            } else if (remainderTen === 3 && remainderHundred !== 13) {
                return `${number}rd`;
            } else {
                return `${number}th`;
            }
        }
    }
};

