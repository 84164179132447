import React, {useEffect, useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { usePHPSession } from "./redux/app/session";
import { useAccessToken } from "./redux/auth/auth";
import { useUser } from "./redux/user";
import { useTours } from "./redux/tours";
import { useClient } from "./redux/client";
import { SkipLink } from './components/a11y/SkipLink';
import AppRoutes from "./AppRoutes";
import {DependencyMapper} from "./util";
import Container from "react-bootstrap/Container";
import Header from "./containers/Header";
import ExportGlobals from "./components/utils/ExportGlobals";
import ProcessForm from "./legacy/components/ProcessForm";
import DeviceBreakpoints from "./legacy/components/DeviceBreakpoints";
import {useRoutes} from "./redux/app/routes";
import {SetupTranslations, useTranslations} from "./redux/translations/translator";
import PageContentSkeleton from "./components/skeletons/PageContentSkeleton";
import {triggerClickOnEnter} from "./utilities/keyboardNavigation";
import {loadMenu} from "./redux/app/menus";
import './App.scss';

function App(props) {
    const {sessionId}  = usePHPSession();
    const authState = useAccessToken();
    const user = useUser();
    const clientState = useClient();
    const defaults = useRoutes();
    const translations = useTranslations();
    const mainNavTranslations = SetupTranslations();
    const tours = useTours();

    const initialPageLoadKey = 'initialPageLoad';
    const dataFetchReadyKey = 'dataFetchReady';
    const { accessToken } = authState;
    const [initialPageLoad, setInitialPageLoad] = useState(false);
    const [routesLoaded, setRoutesLoaded] = useState(false);
    const [client_url] = useState( `/${location.pathname.split('/')[1]}`);

    // Creating a key that other scripts can use to know when it's ok to fetch data
    if(!DependencyMapper.completed(dataFetchReadyKey))
    {
        DependencyMapper.on(["sessionId","accessToken"],dataFetchReadyKey);
    }
    // Initial page load flag
    if(!DependencyMapper.completed(initialPageLoadKey))
    {
        DependencyMapper.on(["sessionId","accessToken","userState","clientState","routes","translations.default"],initialPageLoadKey,()=>setInitialPageLoad(true));
    }
    else if(initialPageLoad === false) setInitialPageLoad(true);
    // Show skeleton at least until routes are loaded
    if(!routesLoaded && DependencyMapper.completed(["routes"])) setRoutesLoaded(true);

    const skeleton = {skeleton:!initialPageLoad};

    //Nav to default route
    const defaultPages = Object.values(defaults).filter(route => route.deepLink);
    if(defaultPages.length > 0){
        DependencyMapper.on(initialPageLoadKey,'defaultRoute',()=>{
            let path = Array.isArray(defaultPages[0].path) ? defaultPages[0].path[0] : defaultPages[0].path;
            if(defaultPages[0].deepLink.action) path += `/${defaultPages[0].deepLink.action}`;
            navigation.followPath(
                path,
                defaultPages[0].deepLink.action,
                defaultPages[0].deepLink.data);
        });
    }

    DependencyMapper.on(initialPageLoadKey, 'applicationEventsInitialization', () => window.applicationEvents.initialize());
    DependencyMapper.on(dataFetchReadyKey, 'loadMenus', () => {
        loadMenu({
            id:'main',
            version:'v1'
        });
        loadMenu({
            id:'quick_menu',
            version:'v1'
        });
    });

    useEffect(() => {
        document.body.addEventListener("keypress", triggerClickOnEnter); // add to body so it works with modals too
    },[]);

    if(!sessionId) return null;

    return (
        <div className="App">
            <BrowserRouter basename={client_url + "#"}>
                <SkeletonTheme color="#ddd" highlightColor="#e5e5e5">
                    <DeviceBreakpoints />
                    <ExportGlobals />
                    <ProcessForm PHPSESSID={sessionId} />
                    <Header skeleton={!initialPageLoad}/>
                    <SkipLink/>
                    <Container id="page-container" className="page-container px-0" aria-live={"polite"}>
                        {!routesLoaded &&
                            <PageContentSkeleton page={'/'}/>
                        }
                        <AppRoutes routes={defaults} client_url={client_url} skeleton={skeleton}/>
                    </Container>
                </SkeletonTheme>
            </BrowserRouter>
        </div>
    );
}

export default App;
